<template>
    <div class="page">
        <el-row class="tool-bar">
            <el-col :span="12">
                <search-tool v-model="search" @input="$utils('search', getCountries)"/>
            </el-col>
            <el-col :span="12" align="right">
                <el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/drzave/create')" v-if="$utils('roleUser', 'Drzave', 'create')">{{ $t('global.novo') }}</el-button>
            </el-col>
        </el-row>
        <el-row>
            <!--Table-->
            <el-table v-if="drzave.length"
                      :data="drzave"
                      ref="table"
                      :default-sort="sortBy"
                      @sort-change="sort => $utils('changeSort', sort, getCountries)"
                      v-loading="gridLoad"
                      border
                      stripe>
                <el-table-column
                        align="left"
                        prop="naziv"
                        :label="$t('drzave.naziv')"
                        sortable="custom">
                </el-table-column>
                <el-table-column
                        align="left"
                        prop="skraceno"
                        :label="$t('drzave.skraceno')"
                        sortable="custom">
                </el-table-column>
                <el-table-column
                        align="left"
                        prop="oznaka"
                        :label="$t('drzave.oznaka')"
                        sortable="custom">
                </el-table-column>
                <el-table-column
                        align="left"
                        prop="naziv_valute"
                        :label="$t('drzave.valuta')">
                </el-table-column>
                <el-table-column
                        width="120">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top" v-if="$utils('roleUser', 'Drzave', 'edit')">
                            <el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/drzave/' + scope.row.id + '')" size="small"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Drzave', 'delete')">
                            <el-button type="danger" icon="fas fa-trash" @click="$delete('drzave', scope.row.id)" size="small"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
        </el-row>
        <br>
        <el-row>
            <pagination v-model="pagination" @input="getCountries" ref="pagination"/>
        </el-row>
    </div>
</template>

<script>
export default {
	name: 'drzave-list',
	data() {
		return {
			drzave: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'naziv',
				order: 'ascending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('drzave.drzave')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getCountries()]);
	},
	methods: {
		getCountries() {
			this.gridLoad = true;
			return this.$get('drzave', this.$utils('getParams')).then(data => {
				this.drzave = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		}
	}
};
</script>
